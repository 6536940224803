<template>
  <ul class="case-list">
    <li v-for="(item, index) in list" :key="index">
      <h3>{{item.title}}</h3>
      <img :src="item.img"/>
      <p>{{item.remark}}</p>
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      list:[
        {img:'/static/img/dd-img4.jpg',title:'四川铁建',remark:'作为西南地区首家落地的专属钉钉项目，从组织在线、业务在线的角度对四川铁建内部信息化系统进行有效整合，全面实现数字化转型。'},
        {img:'/static/img/dd-img5.jpg',title:'长城汽车',remark:'长城汽车&钉钉合力打造简单、高效、协同、安全的数字化专属办公平台，重新定义移动办公方式，全面实现“五个在线”。'},
        {img:'/static/img/dd-img6.jpg',title:'金科集团',remark:'基于钉钉专属定制的美好金科是集办公、协同、知识于一体的统一移动平台，它实现了组织、业务和沟通的无缝集成和实时在线。'},
        {img:'/static/img/dd-img7.jpg',title:'蒙牛集团',remark:'专属钉钉帮助蒙牛实现了集团、各分子公司和经销商体系的数字化平台构建，实现组织内外部的高效沟通协同，业务数字化全场景覆盖。'},
        {img:'/static/img/dd-img8.jpg',title:'立白集团',remark:'专属钉钉通过混合云部署架构实现专属存储，确保数据安全，全面打造立白智能协同平台，实现零售生态、物流生态的数字化运营。'},
        {img:'/static/img/dd-img9.jpg',title:'百丽（Belle）',remark:'通过专属钉钉让百丽业务协同效率大幅提升 ，助力产品优化、管理决策；疫情期间，实现了300多个城市的门店员工的及时响应和调动。'},
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.case-list{
  display: flex;
  align-items:stretch;
  flex-wrap: wrap;
  >li{
    width: 50%;
    padding: 15px 15px 20px;
    color: #383838;
    position: relative;
    margin: initial;
    >img{
      width: 100%;
      display: block;
      margin: 10px 0;
      z-index: 2;
      position: relative;
    }
    >h3{
      font-size: 15px;
      font-weight: 300;
      text-align: center;
      text-transform: uppercase;
      z-index: 2;
      position: relative;
    }
    >p{
      font-size: 13px;
      font-weight: 300;
      line-height: 1.5;
      z-index: 2;
      position: relative;
      text-align: justify;
    }
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      bottom: 3px;
      right: 3px;
      background: #FFFFFF;
      border: 1px solid #E8EAED;
    }
  }
}
</style>