<template>
  <div class="intro">
    <h3>企业数字化办公运营面临的挑战</h3>
    <p>如何保障信息安全及业务生态之间的完美融合</p>
    <Item v-for="(item,index) in list" :item="item" :key="index"/>
  </div>
</template>
<script>
import Item from './Item.vue';
export default {
  components:{
    Item
  },
  data() {
    return {
      list:[
        {img:'/static/img/dd-img1.jpg',title:'自有平台难以建设',remark:'企业如何快速打造专属、高效、安全、稳定、开放的数字化办公运营平台'},
        {img:'/static/img/dd-img2.jpg',title:'信息安全难以保障',remark:'企业上云及协同办公中如何保障全链路的基础安全、业务安全和数据安全'},
        {img:'/static/img/dd-img3.jpg',title:'多业务系统难以融合',remark:'企业如何建立信息化顶层设计，打破“信息孤岛”和“数据烟囱”'}
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.intro{
  padding: 50px 15px;
  color: #383838;
  >h3{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  >p{
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 40px;
  }
}
</style>