<template>
  <div class="advantage-box">
    <h3>产品优势  ·  advantage</h3>
    <AdvantageList/>
  </div>
</template>
<script>
import AdvantageList from './AdvantageList.vue';
export default {
  components:{
    AdvantageList
  }
}
</script>
<style lang="less" scoped>
.advantage-box{
  padding: 50px 10px;
  >h3{
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
  }
}
</style>