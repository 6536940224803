<template>
  <ul class="advantage-list">
    <li v-for="(item, index) in list" :key="index">
      <img :src="item.img"/>
      <h3>{{item.title}}</h3>
      <p>{{item.remark}}</p>
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      list:[
        {img:require('@/assets/dingtalk/dd-icon1.png'),title:'定制专属APP',remark:'全面定制独立打包，自主设置APP的Logo和名称，支持PC、MAC及移动端等多端统一体验。'},
        {img:require('@/assets/dingtalk/dd-icon2.png'),title:'全方位的安全服务',remark:'全链接的数据加密、信息协同安全管控、建立专属数据空间实现业务与数据的分离。'},
        {img:require('@/assets/dingtalk/dd-icon3.png'),title:'业务融合',remark:'提供1700+专属API助力企业深度打通、融合各业务系统，全面提升业务协同效率。'},
        {img:require('@/assets/dingtalk/dd-icon4.png'),title:'专属设计',remark:'可自定义APP的Logo、名称、登录页、启动页、皮肤、工作台、菜单、底部工具栏等界面。'},
        {img:require('@/assets/dingtalk/dd-icon5.png'),title:'miniOSS',remark:'基于混合云的存储阵列可部署在客户机房，数据归属客户，安全稳定性价比高，支持钉钉文件直存。'},
        {img:require('@/assets/dingtalk/dd-icon6.png'),title:'专属安全',remark:'解决企业上云协同办公过程存在的基础安全、业务安全及数据安全的顾虑，提升业务数据保护能力。'},
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.advantage-list{
  display: flex;
  align-items:stretch;
  flex-wrap: wrap;
  >li{
    width: 50%;
    padding: 20px;
    color: #383838;
    position: relative;
    margin: initial;
    >img{
      width: 50px;
      height: 50px;
      display: block;
      margin: auto;
    }
    >h3{
      margin-top: 10px;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }
    >p{
      font-size: 13px;
      font-weight: 300;
      line-height: 1.5;
      margin-top: 10px;
      text-align: justify;
    }
    &::after{
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      bottom: 3px;
      right: 3px;
      background: #FFFFFF;
      border: 1px solid #E8EAED;
      z-index: -1;
    }
  }
}
</style>